import AppLoaderFallback from "components/AppLoaderFallback";
import React from "react";

const App = React.lazy(()=>import("App"));

const AppLoader = () => {
	console.debug("AppLoader");
	return (
		<React.Suspense fallback={<AppLoaderFallback />}>
			<App />
		</React.Suspense>
	);
}

export default AppLoader;