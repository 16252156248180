//import loadingimg from '@esslims/shared-js/components/LoadingIcon/loading.svg';
import React from 'react';

const Fallback = () => {
	console.debug("AppLoaderFallback");
	return <div className="AppLoader bg-secondary">
		<img src="/logo11.png" className="logo" height="150" width="109" alt="logo" />
		<div className="title">Exact Scientific Services<span className="dots fadeInLater">...</span></div>
		<img src="/loading.svg" className="spinner" height="64" width="64" alt="" />
		<div className="feedback fadeInMuchLater">
			<div>This isn&apos;t working.</div>
			<form action="/feedback">
				<button type="submit">Notify support staff.</button>
			</form>
		</div>
		<div className="version-container"><small>{process.env.REACT_APP_VERSION}</small></div>
	</div>;
}

export default Fallback;