import AppLoader from "AppLoader";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

var canPromise = !! window.Promise;
var canFetch = !!window.fetch;

if( canPromise && canFetch ) {
	ReactDOM.render(
		<React.StrictMode>
			<AppLoader />
		</React.StrictMode>,
		document.getElementById("root")
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
